import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Typography,
  Box,
  List,
  IconButton,
  SwipeableDrawer,
  Button,
  Collapse,
  ListSubheader,
} from "@mui/material";
import { Link } from "react-router-dom";
// css
import "../../assets/css/navbar.css";
// images
import logo from "../../assets/images/logo.png";
// icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
// import { logout } from "../../actions/ContextActions";
import { setAlert, setAuth, setCLP } from "../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Drawer,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListAltIcon from "@mui/icons-material/ListAlt";
// context
// import { LOGIN_MODAL } from '../../../store/Types';
// import Store from '../../../store/Context';

// GA
import { tracker } from "../../App";
import { links } from "./data";
import { StarBorder } from "@mui/icons-material";

export default function Navbar(props) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [dropdown, setDropdown] = useState(false);

  const [open, setOpen] = useState(false);
  const history = props.history;
  const [trans, setTrans] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      if (window.scrollY > 100) {
        setTrans(false);
      } else setTrans(true);
    });
  }, []);
  // function for handle login activity
  function handleLog() {
    if (auth.isAuth) history("/profile");
    else {
      history("/login");
    }
  }

  function handleDropdown(state) {
    setDropdown(state);
  }

  function handleLogout() {
    dispatch(setAuth({ isAuth: false }));
    dispatch(setCLP([]));
    dispatch(
      setAlert({
        open: true,
        variant: "success",
        message: "Logged out successfully !!!",
      })
    );
    history("/");
  }

  return (
    <>
      <Box className={`navContainer ${!trans && "bgWhite"}`}>
        {/* LOGO  */}
        <Box
          className="logo"
          component={Link}
          to={"/"}
          onClick={() => tracker("Homepage action", "Header Icon Press")}
        >
          <img src={logo} alt="logo" />
        </Box>
        {/* LINKS  */}
        <Box component={List} className="links">
          <Typography variant={"body"} to={"/"} component={Link}>
            Home
          </Typography>
          <Dropdown></Dropdown>
          <Typography variant={"body"} to={"/about"} component={Link}>
            About Us
          </Typography>
          <Typography variant={"body"} to={"/contact"} component={Link}>
            Contact
          </Typography>
          <Typography
            variant={"body"}
            to={"https://blogs.classbazaar.com/"}
            component={Link}
          >
            Blog
          </Typography>
          <Typography variant={"body"} to={"/career"} component={Link}>
            Career
          </Typography>
        </Box>
        {/* Button */}
        <Box className="button">
          <IconButton size="small" onClick={handleLog}>
            <PersonOutlineOutlinedIcon />
          </IconButton>
          {auth.isAuth && (
            <IconButton
              onClick={handleLogout}
              className="searchBTN"
              size="small"
            >
              <PowerSettingsNewIcon />
            </IconButton>
          )}
        </Box>
        {/* Han Icon */}
        <IconButton className="hamIcon" onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Hamburger
        open={open}
        setOpen={setOpen}
        history={history}
        dispatch={dispatch}
        state={auth}
        handleLogout={handleLogout}
      />
    </>
  );
}

function Hamburger({ open, setOpen, history, dispatch, state, handleLogout }) {
  const [courseDropdown, setState] = useState(false);

  // function for handle login activity
  function handleLog() {
    if (state.isAuth) history("/profile");
    else {
      history("/login");
    }
  }

  function LinkBox() {
    return (
      <>
        <IconButton
          onClick={() => setOpen(false)}
          size="small"
          className="closeBTN"
        >
          <CloseIcon />
        </IconButton>
        <HamCourses
          state={courseDropdown}
          setState={setState}
        ></HamCourses>
        <Box className="hamLinkContainer">
          <ul className="ul_formate">
            <Link to="/" onClick={() => setOpen(false)} className="li_formate">
              Home
            </Link>
            <Link
              onClick={() => {
                setOpen(false);
                setState(open);
              }}
              className="li_formate"
            >
              Courses
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to="/about"
              className="li_formate"
            >
              About Us
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to="/contact"
              className="li_formate"
            >
              Contact
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to={"https://blogs.classbazaar.com/"}
              className="li_formate"
            >
              Blog
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to="/career"
              className="li_formate"
            >
              Career
            </Link>
            {state.isAuth && (
              <Link
                onClick={() => setOpen(false)}
                to="/profile"
                className="li_formate"
              >
                Profile
              </Link>
            )}
            <Button
              variant="contained"
              onClick={() => {
                {
                  state.isAuth ? handleLogout() : handleLog();
                }
              }}
              className="logOutBtn"
            >
              {state.isAuth ? "Logout" : "Login"}
            </Button>
          </ul>
        </Box>
      </>
    );
  }

  return (
    <>
      <SwipeableDrawer anchor={"right"} open={open}>
        <LinkBox />
      </SwipeableDrawer>
    </>
  );
}

function Dropdown() {
  let [tab, setTab] = useState(null);


  return (
    <Typography id="courses" variant={"body"} component={Link}>
      Courses
      <ul id="submenu">
        {Object.keys(links).map((link) => (
          <li
            key={link}
            onMouseEnter={() => {
              setTab(link);
            }}
          >
            <div>
              {link} <ArrowForwardIosIcon fontSize="10" />
            </div>
            {/* Nested menu within each li */}
            <ul id="nested-menu">
              {tab &&
                links[tab].map((row, index) => (
                  <Typography
                    component={Link}
                    to={`/listing/${replaceSpacesWithUnderscore(row)}/true`}
                    key={index}
                  >
                    <li>{row}</li>
                  </Typography>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </Typography>
  );
}

function HamCourses({ state, setState }) {
  const anchor = "right";
  const toggleDrawer = (anchor, open) => {
    setState(open);
  };
  
  const [open, setOpen] = useState({});

  const handleClick = (tab) => {
    setOpen(old=>({...old,[tab]:!old[tab]}));
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {Object.keys(links).map((row) => (
          <Box>
            <ListItemButton onClick={()=>handleClick(row)}>
              <ListItemIcon >
                <ListAltIcon color="primary" fontSize="large" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontSize: '15px'}}  primary={row} />
              {open[row] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[row]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {links[row].map((link) => (
                  <ListItemButton
                    component={Link}
                    onClick={() => toggleDrawer("left", false)}
                    to={`/listing/${replaceSpacesWithUnderscore(link)}/true`}
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon sx={{width : "10px"}}>
                      <MenuBookIcon color="primary" ></MenuBookIcon>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{fontSize: '16px'}} primary={link} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={anchor}
          open={state}
          onClose={() => toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

function replaceSpacesWithUnderscore(inputString) {
  return inputString.replace(/ /g, "-");
}