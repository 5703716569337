import A from "../../assets/other/A.png";
import B from "../../assets/other/B.png";
import CS from "../../assets/other/CS.png";
import DA from "../../assets/other/DA.png";
import DEV from "../../assets/other/DEV.png";
import HL from "../../assets/other/HL.png";
import M from "../../assets/other/M.png";
import O from "../../assets/other/O.png";
import SENG from "../../assets/other/SENG.png";
import SO from "../../assets/other/SO.png";
import dataScience from "../../assets/subjects/20402.jpg";
import scienceAndEngin from "../../assets/subjects/female-engineer-in-laboratory-3861449.jpg";
import socialStudies from "../../assets/subjects/happy-ethnic-woman-sitting-at-table-with-laptop-3769021.jpg";
import computerScience from "../../assets/subjects/Image-2.jpg";
import art from "../../assets/subjects/Image-3.jpg";
import business from "../../assets/subjects/Image-4.jpg";
import mathImage from "../../assets/subjects/Image-16.jpg";
import developer from "../../assets/subjects/person-looking-at-phone-and-at-macbook-pro-1181244.jpg";
import health from "../../assets/subjects/photo-of-woman-practicing-yoga-3820320.jpg";

// icons
import affiliate from "../../assets/furimg/affiliate.png";
import dataAnalyst from "../../assets/furimg/dataAnalyst.png";
import ux from "../../assets/furimg/ux.png";
import video from "../../assets/furimg/video.png";
import basic from "../../assets/furimg/basic.png";
import communication from "../../assets/furimg/communication.png";
import contentCreation from "../../assets/furimg/content-creation.png";

export const subjectsData = [
  {
    name: "Computer Science",
    code: "CS",
    content: "",
    image: CS,
    tile: computerScience,
  },
  {
    name: "Business",
    code: "B",
    content: "",
    image: B,
    tile: business,
  },
  {
    name: "Arts & Design",
    code: "A",
    content: "",
    image: A,
    tile: art,
  },
  {
    name: "Data Science",
    code: "DA",
    content: "",
    image: DA,
    tile: dataScience,
  },
  {
    name: "Health & Lifestyle",
    code: "HL",
    content: "",
    image: HL,
    tile: health,
  },
  {
    name: "Science & Engineering",
    code: "SENG",
    content: "",
    image: SENG,
    tile: scienceAndEngin,
  },
  {
    name: "Social Studies",
    code: "SO",
    content: "",
    image: SO,
    tile: socialStudies,
  },
  {
    name: "Developers/Programming",
    code: "DEV",
    content: "",
    image: DEV,
    tile: developer,
  },
  {
    name: "Math",
    code: "M",
    content: "",
    image: M,
    tile: mathImage,
  },
  {
    name: "Others",
    code: "O",
    content: "",
    image: O,
  },
];

export const FRD = [
  {
    name: "Data Visualization and Building Dashboards with Excel and Cognos",
    university: "Coventry University",
    provider: "edX",
    icon: dataAnalyst,
    url: "/edX/87970/DataVisualizationandBuildingDashboardswithExcelandCognos",
  },
  {
    name: "The Complete Guide to Partnership Marketing Course",
    university: "Udemy",
    provider: "Udemy",
    icon: affiliate,
    url: "/Udemy/178315/the-complete-guide-to-partnership-marketing-course",
  },
  {
    name: "How to Create Great Online Content",
    university: "University of Leeds",
    provider: "Future Learn",
    icon: contentCreation,
    url: "/FutureLearn/182486/how-to-create-great-online-content",
  },
  {
    name: "Improve Communication: Speak Smoothly, Clearly & Confidently",
    university: "Udemy",
    provider: "Udemy",
    icon: communication,
    url: "/Udemy/68451/improve-communication-speak-smoothly-clearly-and-confidently",
  },
  {
    name: "Introduction to User Experience Design",
    university: "Georgia Institute of Technology",
    provider: "Udemy",
    icon: ux,
    url: "/Udemy/158428/introduction-to-user-experience=design",
  },
  // {
  //   name: "Video Production: 10 Ways to Instantly Improve Your Videos",
  //   university: "Udemy",
  //   provider: "Udemy",
  //   icon: video,
  //   url: "/Udemy/e96fb258-fd65-4d11-8fe3-1c3f52b80c15",
  // },
  // {
  //   name: "Managing Emotions in Times of Uncertainty & Stress",
  //   university: "Yale University",
  //   provider: "Coursera",
  //   icon: basic,
  //   url: "/Coursera/667ff468-f4ee-41bf-b555-7b0913256202",
  // },
  // {
  //   name: "Complete Google Workspace (G Suite), Beginner - Advanced",
  //   university: "Udemy",
  //   provider: "Udemy",
  //   icon: basic,
  //   url: "/Udemy/90ff991e-98db-4478-8424-ec220657a5b5",
  // },
  // {
  //   name: "Excel for Everyone: Core Foundations",
  //   university: "edX",
  //   provider: "edX",
  //   icon: basic,
  //   url: "/edX/920f55b7-9ce4-446d-a8c6-161717a4aaa6",
  // },
];
export const links = {
  "Computer Science": [
    "It & Software",
    "Other It & Software",
    "Computer Science",
    "Network & Security",
    "Mobile Development",
    "Game Development",
    "Web Development",
    "Operating Systems & Servers",
    "Software Development Tools",
    "Software Testing",
    "It & Computer Science",
    "Cybersecurity",
    "Database Design & Development",
    "Software Engineering",
    "No-Code Development",
    "Offensive Security",
    "Cloud Computing",
    "Cloud Certifications",
    "Cloud Development",
    "Networking",
    "It Certifications"
  ],
  "Arts & Design": [
    "Design",
    "Graphic Design & Illustration",
    "Arts & Design",
    "Arts & Crafts",
    "3D & Animation",
    "Photography Tools",
    "Photography & Video",
    "Music",
    "Music Production",
    "Instruments",
    "Music Fundamentals",
    "Interior Design",
    "Fashion Design",
    "Creative Arts & Media",
    "Music Techniques",
    "User Experience Design",
    "Portrait Photography",
    "Vocal",
    "Arts & Creativity"
  ],
  Business: [
    "Business",
    "Marketing",
    "Product Marketing",
    "Digital Marketing",
    "E-Commerce",
    "Project Management",
    "Real Estate",
    "Accounting & Bookkeeping",
    "Entrepreneurship",
    "Management",
    "Business Law",
    "Career Development",
    "Business Strategy",
    "Other Business",
    "Compliance",
    "Paid Advertising",
    "Content Marketing",
    "Investing & Trading",
    "Sales",
    "Safety & First Aid",
    "Operations",
    "Taxes",
    "Business & Management",
    "Finance",
    "Money Management Tools",
    "Branding",
    "Growth Hacking",
    "Marketing Fundamentals",
    "Financial Modeling & Analysis",
    "Industry",
    "Customer Success & Customer Service",
    "Philanthropy",
    "Finance & Accounting",
    "Healthcare & Medicine",
    "Leadership & Management",
    "Project Management Tools",
    "Operations Management",
    "Leadership"
  ],
  "Data Science": [
    "Data Science",
    "Big Data",
    "Data Visualization",
    "Analytics",
    "Statistical Analysis",
    "Business Analytics & Intelligence"
  ],
  "Health & Lifestyle": [
    "Health & Lifestyle",
    "Pet Care & Training",
    "Dance",
    "Yoga",
    "Nutrition & Diet",
    "Beauty & Makeup",
    "Meditation",
    "Martial Arts & Self Defense",
    "Sports",
    "Nature & Environment",
    "Religion & Spirituality",
    "Stress Management",
    "Parenting & Relationships",
    "Fitness",
    "Mental Health",
    "Medicine",
    "Happiness",
    "Self Esteem & Confidence",
    "Other Health & Fitness",
    "General Health",
    "Psychology & Mental Health",
    "Biology & Life Sciences",
    "Life Skills",
    "Health & Fitness",
    "Health & Safety",
    "Healthcare & Medicine",
    "Personal Transformation"
  ],
  "Science & Engineering": [
    "Science",
    "Engineering",
    "Energy & Earth Sciences",
    "Physics",
    "Chemistry",
    "Biology & Life Sciences",
    "Environmental Studies",
    "Science, Engineering & Maths"
  ],
  "Social Studies": [
    "Social Studies",
    "Language Learning",
    "Humanities",
    "Politics & Society",
    "Economics",
    "Social Science",
    "History",
    "Religion & Spirituality",
    "Philanthropy",
    "Ethics",
    "Psychology & Mental Health",
    "Philosophy & Ethics"
  ],
  Programming: [
    "Developers/Programming",
    "Programming Languages",
    "Web Development",
    "Mobile Development",
    "Game Development",
    "No-Code Development",
    "Software Development Tools",
    "It & Software",
    "It & Computer Science"
  ],
  Math: [
    "Math",
    "Statistical Analysis"
  ],
  Other: [
    "Teaching & Academics",
    "Office Productivity",
    "Microsoft",
    "Test Prep",
    "Other Personal Development",
    "Personal Productivity",
    "Other Teaching & Academics",
    "Teacher Training",
    "Study Skills",
    "Memory & Study Skills",
    "Online Education",
    "Google",
    "Course",
    "Other Lifestyle",
    "Travel",
    "Home Improvement & Gardening",
    "Communication",
    "Personal Brand Building",
    "Social Media Marketing",
    "Motivation",
    "Language",
    "Others",
    "Word Processing",
    "Spreadsheets",
    "Workplace & Human Resources",
    "Finance Cert & Exam Prep",
    "Human Resources",
    "Human Resources Fundamentals"
  ]
};
