import React, { useEffect } from 'react';
import { useParams } from 'react-router';
// import Store from '../store/Context';
import { useDispatch } from 'react-redux';
import { welcome, newLogin } from '../services/services';
import { setAuth, setAlert } from '../../redux/action/action';
import { Box } from '@mui/material';

const Verified = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    autoSinging();
  }, []);
  let {email,password} = useParams();
  
  async function autoSinging() {
    try {
     
    let data = {
      email_address: email,
      password: password,
    };

    await welcome(data.email_address);

    let response = await newLogin(data);

    if (response.status === 200) {
      dispatch(
        setAuth({
          isAuth: true,
          email: response.data.user.email_address,
          name: response.data.user.name,
          mobile_no: response.data.user.mobile_no,
          token: response.data.token,
        })
      );
      dispatch(
        setAlert({
          open: true,
          variant: 'success',
          message: response.message || 'Log In Successfully !!!',
        })
      );

      return history('/');
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: 'error',
          message: response.message || 'User Not Found !!!',
        })
      );
    }
     
  } catch (error) {
      console.log(error)
  }
  }

  return (
    <Box mt = {10} p = {2} sx = {{}} >
      <center><h4>Redirecting....</h4></center>
    </Box>
  );
};

export default Verified;
