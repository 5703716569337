import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// images
import signUp from "../../assets/images/logIn.png";
import top from "../../assets/images/topVector.png";
import bottom from "../../assets/images/bottomVector.png";


// css
import "../../assets/css/signup.css";
import "../../assets/css/utility.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setAuth } from "../../redux/action/action";

// login API
import { newLogin, updatePassword } from "../services/services";
import { Helmet } from "react-helmet";
import {tracker} from "../../App";
import useTrackTime from "../../hook/useTrackTime";

export default function ResetPassword(props) {
  const dispatch = useDispatch();
  let {token} = useParams();

  async function handleReset(e) {
    try {
      e.preventDefault();
      tracker("Reset Password","User click on reset button");
      let data = {
        token,
        password : e.target.password.value
      };
      let response = await updatePassword(data);

      if (response.status === 200) {
        tracker("User password updated.","User password updated.",token);
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: response.data.message,
          })
        );
        props.history("/login");
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: response.data.message || "User Not Found !!!",
          })
        );
      }
    } catch (err) {
      tracker("Reset Password","Failed");
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong !!!",
        })
      );
    }
  }

  return (
    <>
      {/* helmet tag  */}
      <Helmet>
        <title>Reset Password</title>
        <meta
          name="description"
          content="Let Login and explore the world of courses with Class Bazaar."
        />
      </Helmet>
      {/* helmet tag ends  */}
      <Grid container className="signContainer">
        <Grid item xs={12} md={6} className="imageContainer">
          <img src={top} className="topVector" alt="top" />
          <img src={signUp} className="mainImage" alt="signUp" />
        </Grid>
        {/* // form ======  */}
        <Grid item xs={12} className="signUpForm" md={6} >
          <img src={bottom} className="bottomVector" alt="bottom" />
          <center>
          <Typography variant="h4" className="signTextColor b6">
            Reset Password
          </Typography>
          <Typography
            // to="/signUp"
            // component={Link}
            className="signTextColor"
            variant="body"
          >
            Reset your password using your email.
          </Typography>
          </center>
          <form
            method="post"
            onSubmit={handleReset}
            encType="multipart/form-data"
          >
            <FormGroup className = 'formFelids' >
              <FormControlLabel
                className="signTextColor"
                labelPlacement="top"
                control={
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    placeholder={"New password"}
                    size="medium"
                    name="password"
                  />
                }
                label="Password"
              />
              <Button
                size="medium"
                variant="contained"
                className="signButtonColor"
                type="submit"
              >
                Submit
              </Button>
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
