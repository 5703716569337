import {
  Box,
  Typography,
  Link,
  Grid,
  FormControlLabel,
  TextField,
  FormGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
// css
import "../../assets/css/career.css";

// image
import girl from "../../assets/career/girl.png";
import boy from "../../assets/career/boy.png";
import tableView from "../../assets/career/tableView.png";
import { meetUp } from "../services/services";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/action/action";
import { Send } from "@mui/icons-material";
import {tracker} from "../../App";
import useTrackTime from "../../hook/useTrackTime";

const Career = () => {
  let dispatch = useDispatch();
  const timeSpan = useTrackTime();

  const opp = [
    { title: "Saas Sales Specialist", exp: 5, location: "Mumbai" },
    { title: "Saas Sales Specialist", exp: 5, location: "Mumbai" },
    { title: "Saas Sales Specialist", exp: 5, location: "Mumbai" },
  ];

  const [file, setFile] = useState(" ");
  const [data, setData] = useState({ isLoading: false });

  function handleValue(e) {
    setData((old) => ({ ...old, [e.target.name]: e.target.value }));
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      tracker("Career","User submitting resume");
      setData(old=>({...old, isLoading: true }));

      const FD = new FormData();

      FD.append("name", data.name);
      FD.append("email", data.email);
      FD.append("contact", data.contact);
      FD.append("profile", data.profile);
      FD.append("resume", file);

      const res = await meetUp(FD);

      console.log(res);

      if (res.status === 200) {
      tracker("Career","User submitted resume successfully.");

        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: res.data.message || "Your has been submitted !!!",
          })
        );
        setFile("");

        setData({ isLoading: false });
      }
    } catch (err) {
      setData({ isLoading: false });

      console.log(err);
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something went wrong !!!",
        })
      );
    }
  };

  const handleFileChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
  };

  return (
    <> 
      <Box sx={{ padding: "3% 5% 0% 5%" }}>
        {/* helmet tag  */}
        <Helmet>
          <title>ClassBazaar || Career</title>
        <link rel="canonical" href="https://www.classbazaar.com/career" />
          <meta
            name="description"
            content="Let's shape your career with ClassBazaar."
          />
        </Helmet>
        {/* helmet tag ends  */}
        {/* first section */}

        <Grid container mb={5} mt={5} className={"careerTopContainer"}>
          <Grid item xs={12} className="textOrange careerText" md={6}>
            <Typography variant="h1" className="joinUs">
              Join Us
            </Typography>
            <Typography variant="body1">
              You can leave us a application below and our team will get back to
              you or you can directly email us at info@classbazaar.com
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={"careerTopImageContainer"}>
            <Box id={"boy"}>
              <img src={boy} alt="boy" />
            </Box>
            <Box id={"girl"}>
              <img src={girl} alt="girl" />
            </Box>
          </Grid>
        </Grid>
        {/* first section ends */}

        {/* Opportunity  */}
        <Grid container mb={10} className={"opportunityContainer"}>
          <Grid item xs={12} mb={5} className="textGreen">
            <Typography variant="h2">Opportunity</Typography>
          </Grid>
          <Grid item xs={12} className="opportunityCard">
            {opp.map((row, key) => (
              <Box key={key} className={"oppItem"}>
                <Box className={"oppContentBox"}>
                  <Typography variant={"h4"} className={"textGreen"}>
                    {row.title}
                  </Typography>
                  <Box className="otherInfo">
                    <Typography variant={"caption"}>
                      Experience : {row.exp}
                    </Typography>
                    <Typography variant={"caption"}>
                      Location : {row.location}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
        {/* Opportunity ends */}

        {/* Apply Now */}
        <Grid container>
          <Grid item xs={12} mb={10}>
            <center>
              <Typography
                sx={{ fontWeight: 500 }}
                variant="h3"
                className="textOrange"
              >
                Apply Now
              </Typography>
            </center>
          </Grid>
          <Grid item xs={12} md={6} className="resumeImage">
            <img src={tableView} alt="image_table" />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="contactForm"
            onSubmit={handleSubmit}
            component={"form"}
            method="post"
            encType="multipart/form-data"
            pl={2}
            pr={2}
          >
            {/* fields */}
            <FormGroup className="mainForm">
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    onChange={handleValue}
                    value={data.name || ""}
                    name="name"
                    size="medium"
                    placeholder="Enter your name..."
                  />
                }
                label="Name"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    size="medium"
                    name="email"
                    onChange={handleValue}
                    value={data.email || ""}
                    placeholder="Enter your email..."
                  />
                }
                label="Email"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    size="medium"
                    onChange={handleValue}
                    value={data.contact || ""}
                    name="contact"
                    placeholder="Enter your mobile..."
                  />
                }
                label="Mobile"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    size="medium"
                    onChange={handleValue}
                    value={data.profile || ""}
                    name="profile"
                    placeholder="Apply for..."
                  />
                }
                label="Profile"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    type="file"
                    onChange={handleFileChange}
                    name="resume"
                    inputProps={{
                      style: { visibility: "hidden" },
                    }}
                    fullWidth
                    size="medium"
                    placeholder="What's your concern..."
                  ></TextField>
                }
                label="Resume"
                labelPlacement="top"
              />
              <Button
                className="contactFromButton"
                type="submit"
                disabled={data.isLoading}
                startIcon={
                  data.isLoading === true ? (
                    <CircularProgress size={"20px"} />
                  ) : (
                    <Send />
                  )
                }
                variant="contained"
              >
                Submit
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
        {/* Apply Now ends */}
      </Box>
    </>
  );
};

export default Career;
