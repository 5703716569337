import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { cardData, Intelligence_Point } from "./data";
// css
import "../../assets/css/profile.css";
// image
import avatar from "../../assets/other/user.png";
import imgtest from "../../assets/other/Journalist.png";
import vector from "../../assets/other/vector-card.png";
import { useSelector } from "react-redux";
import { MIIT_base_URI } from "../../config";
import { Link } from "react-router-dom";
import useTrackTime from "../../hook/useTrackTime";
import { tracker } from "../../App";
function Congratulations() {
  const timeSpan = useTrackTime();
  const { auth } = useSelector((state) => state);
  return (
    <>
      <Box sx={{ padding: "0% 5% 0% 5%" }} mb={5}>
        {/* helmet tag  */}
        <Helmet>
          <title>ClassBazaar || Congratulations</title>
          <meta
            name="description"
            content="Profile section is here, let's check your progress together."
          />
        </Helmet>
        {/* helmet tag ends  */}
        {/* Main container */}
        <Grid container mt={3} className="mainContainer_Congress" p={1}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "700" }}
              className="userTextColor"
            >
              Congratulations!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "500", color: "#AFAFAF", lineHeight: "3rem" }}
            >
              You have successfully completed Edubuk's Multiple Intelligence,
              Interest & Talent (M.I.I.T.) Screening.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1.2rem", fontWeight: "500" }}>
              To ensure that you Benefit from the analysis of M.I.I.T. Screening
              Results, kindly go through the information contained in this
              report carefully.
            </Typography>
          </Grid>
          {/* activity cards  */}
          {auth.mi_intelligence.length !== 0 ? (
            <Grid container className="userActivityContainer">
              <Cards
                heading={`Hey ${auth.name}, these are your top Multiple Intelligence types:`}
                item={auth.mi_intelligence}
              />
            </Grid>
          ) : (
            <Grid
              container
              className="d-flex justify-content-center flex-column align-items-center gap-4"
            >
              <Typography
                sx={{ color: "rgba(131, 131, 131, 0.686)" }}
                variant="h5"
              >{`Hey, ${auth.name} take our test and divcover your ture potential.`}</Typography>
              <Button className="custom-btn" variant="contained">
                Take Test
              </Button>
            </Grid>
          )}
          {/* activity cards ends */}
          {/* PERSONALIZED */}
          <Grid item xs={12} className="mt-4">
            <Typography
              variant="h4"
              sx={{ fontWeight: "500" }}
              className="userTextColor"
            >
              PERSONALIZED INTELLIGENCE TRIGON
            </Typography>
            <Box component={"ol"} className="PERSONALIZED-list mt-3">
              <Typography variant="body1" component={"li"}>
                The questions in the MIIT Screening Tests are designed so that
                when you respond to a question, your preference is clearly
                stated and matched with the relevant intelligence type.
              </Typography>
              <Typography variant="body1" component={"li"}>
                Based on your unique set of responses to each question, our
                analysis shows that you have 3 dominant types of intelligences
                as depicted in the trigon presented above.
              </Typography>
              <Typography variant="body1" component={"li"}>
                Based on your dominant intelligence types we have mapped a few
                relevant career paths for you in the next section of this
                report.
              </Typography>
            </Box>
          </Grid>
          {/* PERSONALIZED End*/}
          <Grid item xs={12} pt={1}>
            <Typography
              variant="h3"
              sx={{ lineHeight: "4.5rem", fontWeight: 700 }}
              className="userTextColor"
            >
              “ Everybody is a Genius. But If You Judge a Fish by Its Ability to
              Climb a Tree, It Will Live Its Whole Life Believing that It is
              Stupid ” - Albert Einstein
            </Typography>
          </Grid>
          <Grid item xs={12} className="flex-gap" pt={3}>
            <Typography
              sx={{ fontWeight: 500 }}
              className="shortTextColor"
              variant="h4"
            >
              CAREER MAPPING REPORT
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              We have compiled a list of a few relevant career options for an
              individual possessing Linguistic Intelligence as depicted in the
              diagram above.You should focus more on curricular activities like
              debate, public speaking, writing stories,word games, crossword
              puzzles etc.
            </Typography>
          </Grid>
          {auth.mi_intelligence.length !== 0 &&
            auth.mi_intelligence.map((row) => (
              <Qualities data={Intelligence_Point[row]} />
            ))}
          {/* <Grid item xs={12}>
            <Box
              component={"p"}
              sx={{ lineHeight: "1.5rem", fontWeight: 500, margin: "0%" }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Box>
          </Grid> */}
          {/* <Grid
            container
            className="d-flex justify-content-center flex-column align-items-center gap-4 mt-4"
          >
            <Button
              component={Link}
              to={`${MIIT_base_URI}/MITest/Interest?email_address=${auth.email}`}
              className="custom-btn"
              variant="contained"
            >
              Take Test
            </Button>
          </Grid> */}
        </Grid>
        {/* Main container end */}
      </Box>
    </>
  );
}

function Qualities({ data }) {
  let { heading, ul } = data;
  return (
    <Grid item xs={12}>
      <Typography
        className="shortTextColor"
        sx={{ fontWeight: 500 }}
        variant="h4"
      >
        {heading}
      </Typography>
      <Box className={"Qualities_list_container"}>
        {ul.map((row) => (
          <Box component={"ul"}>
            {row.map((sub_row) => (
              <Typography component={"li"}>{sub_row}</Typography>
            ))}
          </Box>
        ))}
      </Box>
    </Grid>
  );
}

function Cards({ item, heading }) {
  return (
    <Grid item xs={12}>
      {" "}
      <Grid container sx={{ gap: 4 }}>
        {heading && (
          <Grid item xs={12} className="cardHead">
            <Typography variant="h5">{heading}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container className="profileCardContainer">
            {/* // itrate */}
            {item.map((row) => (
              <Grid className="profileCard" item xs={12} md={3}>
                <Box className="profileCardVector">
                  <img src={vector} alt="vector"></img>
                </Box>
                <Box className="profileCardImg">
                  <img
                    sx={{ width: "100%" }}
                    src={cardData?.cards[row]?.img}
                    alt="test"
                  />
                </Box>
                <Box className="profileCardName">
                  <Typography variant="h6">
                    {cardData.cards[row].title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Congress2() {
  let { auth } = useSelector((state) => state);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "500" }}
          className="userTextColor"
        >
          PERSONALIZED INTEREST TRIGON
        </Typography>
        <Box component={"ol"} className="PERSONALIZED-list  mt-3">
          <Typography variant="body1" component={"li"}>
            The questions in the MIIT Screening Tests are designed so that when
            you respond to a question, your preference is clearly stated and
            matched with the relevant intelligence type.
          </Typography>
          <Typography variant="body1" component={"li"}>
            Based on your unique set of responses to each question, our analysis
            shows that you have 3 dominant types of intelligences as depicted in
            the trigon presented above.
          </Typography>
          <Typography variant="body1" component={"li"}>
            Based on your dominant intelligence types we have mapped a few
            relevant career paths for you in the next section of this report.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className="shortTextColor"
          sx={{ fontWeight: 500 }}
          variant="h4"
        >
          Your Interest Trigon
        </Typography>
        <Grid container pt={5}>
          {auth.mi_intelligence.length !== 0 ? (
            <Grid container className="userActivityContainer">
              <Cards item={auth.mi_intelligence} />
            </Grid>
          ) : (
            <Grid
              container
              className="d-flex justify-content-center flex-column align-items-center gap-4"
            >
              <Typography
                sx={{ color: "rgba(131, 131, 131, 0.686)" }}
                variant="h5"
              >{`Hey, ${auth.name} take our test and divcover your ture potential.`}</Typography>
              <Button className="custom-btn" variant="contained">
                Take Test
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-3">
        <Typography
          className="shortTextColor"
          sx={{ fontWeight: 500 }}
          variant="h4"
        >
          Your Career Path Trigon
        </Typography>
        <Grid container pt={5}>
          {auth.mi_intelligence.length !== 0 ? (
            <Grid container className="userActivityContainer">
              <Cards item={auth.mi_intelligence} />
            </Grid>
          ) : (
            <Grid
              container
              className="d-flex justify-content-center flex-column align-items-center gap-4"
            >
              <Typography
                sx={{ color: "rgba(131, 131, 131, 0.686)" }}
                variant="h5"
              >{`Hey, ${auth.name} take our test and divcover your ture potential.`}</Typography>
              <Button
                component={Link}
                to={`${MIIT_base_URI}/MITest/pay`}
                onClick={() => tracker("Button Click", "MITest Button Click")}
                className="custom-btn"
                variant="contained"
              >
                Take Test
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Congratulations;
