import React, { useState, useEffect } from "react";
import Footer from "../utils/Footer";
import Navbar from "../utils/Navbar";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

// image
import timer from "../../assets/images/timer.png";
import level from "../../assets/images/time.png";
import content from "../../assets/images/rupee.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// apis
import { enroll_track, getCoursesDetails } from "../services/services";

import "../../assets/css/courseDetails.css";
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

// GA
import { tracker } from "../../App";
import useTrackTime from "../../hook/useTrackTime";

const Details = () => {
    useTrackTime();

  const { title, provider, index_no } = useParams();
  console.log(index_no)

  const { auth } = useSelector((state) => state);
  const [data, setData] = useState({});

  useEffect(() => {
    getCourse(title);
  }, [title]);

  // useEffect(() => {
  //   console.log(auth.isAuth);
  //   if (!auth.isAuth) window.location = "/login";
  // }, [auth]);

  async function getCourse() {
    let response = await getCoursesDetails({ title, provider, index_no });

    if (response.data.summaryData) setData(response.data.summaryData);
    else setData({ message: "No course details found !!!" });
  }

  async function handleEnroll(data) {
    if(!auth.isAuth)
      window.location.pathname = '/login'
    tracker("Course Action", "Enroll Now", data.title);
    enroll_track({
      email: auth.email,
      course_title: data.title,
      url: data.url,
      user_name: auth.name,
    });
  }

  return (
    <>
      {/* helmet tag  */}
      <Helmet>
        <title>{`ClassBazaar || ${data && data.title}`}</title>
        <meta
          name="description"
          content="Class Bazaar is a search engine and reviews platform for massive open online courses (MOOCs). Our aim is to help you Stop Searching & Start Learning!"
        />
      </Helmet>
      {/* helmet tag ends  */}
      {/* Course Details  */}
      {data && data.title ? (
        <Grid container mt={12} className="courseDetailsContainer">
          <Grid item xs={12}>
            <Typography className=" colorTitle universityTitle" variant="h6">
              {data.university}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="colorTitle courseTitle" variant="h6">
              {data.title}
            </Typography>
          </Grid>
          <Grid item xs={12} className="otherDetails">
            <Grid container p={1} className="secSec">
              <Grid item xs={3} className="infoIcon">
                <img src={timer} alt="timer" />
                <Typography variant="h5">
                  {data.start_date ? data.start_date.split("T")[0] : "Flexible"}
                </Typography>
              </Grid>
              <Grid item xs={3} className="infoIcon">
                <img src={level} alt="level" />
                <Typography variant="h5">Any</Typography>
              </Grid>
              <Grid item xs={3} className="infoIcon">
                {/* <img src={content} alt="duration" /> */}
                <CurrencyRupeeIcon />
                <Typography variant="h5">
                  {parseInt(data.price) || "Free"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  component={Link}
                  onClick={() => handleEnroll(data)}
                  href={data.url}
                  target="_blank"
                  variant="contained"
                  size={"medium"}
                  className="enrollBTN"
                >
                  Enroll Now
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className="colorTitle courseOverview">
              Course Overview
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12} className="description">
            <Typography variant="body1">
              {ReactHtmlParser(data.description)}
            </Typography>
          </Grid>
        </Grid>
      ) : data.message ? (
        <Grid container className="courseDetailsContainer">
          <Grid className={"progress"} xs={12}>
            <Typography variant="h5">&#128533; {data.message} </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="courseDetailsContainer">
          <Grid className={"progress"} xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}

      {/* Course Details Ends */}
      {/* Footer  */}
      {/* <Footer /> */}
      {/* Footer ends */}
    </>
  );
};

export default Details;
