import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { cardData, CLPData } from "./data";
// css
import "../../assets/css/profile.css";
// image
import avatar from "../../assets/other/user.png";
import imgtest from "../../assets/other/Journalist.png";
import vector from "../../assets/other/vector-card.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MIIT_base_URI } from "../../config";
import {
  getUserDetails,
  saveCLPOptions,
  setCLPOptions,
} from "../services/services";
import { setAlert, setAuth, setCLP } from "../../redux/action/action";
import useTrackTime from "../../hook/useTrackTime";
import { tracker } from "../../App";
import { Height } from "@mui/icons-material";
import Congratulations from "./CongressPage";
import { CongressInterest } from "./CongressInterest";
function Profile({ history }) {
  const { auth  } = useSelector((state) => state);
  const {clp} = auth;
  const timeSpan = useTrackTime();
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth.isAuth === false) window.location.pathname = "/login";
    else {
      fetchUser();
    }
  }, [auth.isAuth]);
  async function fetchUser() {
    let res = await getUserDetails(auth.email);
    if (res.status !== 200)
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Error while fetching user Successfully !!!",
        })
      );

    const { mi_intelligencies, mi_intelligencies_interest } = res.data.data;
    console.log(mi_intelligencies, mi_intelligencies_interest);
    dispatch(
      setAuth({
        ...auth,
        mi_intelligence: mi_intelligencies ? JSON.parse(mi_intelligencies) : [],
        mi_interest: mi_intelligencies_interest
          ? JSON.parse(mi_intelligencies_interest)
          : [],
      })
    );
  }

  return (
    <>
      <Box sx={{ padding: "3% 5% 0% 5%" }} mb={5}>
        {/* helmet tag  */}
        <Helmet>
          <title>ClassBazaar || Profile</title>
        <link rel="canonical" href="https://www.classbazaar.com/profile" />
          <meta
            name="description"
            content="Profile section is here, let's check your progress together."
          />
        </Helmet>
        {/* helmet tag ends  */}
        {/* Main container */}
        <Grid container mt={3} className="mainContainer">
          {/* user details */}
          <Grid container className="userContainer ">
            <Grid
              item
              xs={12}
              md={2}
              className="d-flex justify-content-center "
            >
              <Box className="userImg">
                <img src={avatar} style={{ width: "100%" }} alt="user_name" />
              </Box>
            </Grid>
            <Grid item xs={12} md={10} p={1}>
              <Box
                component={"ui"}
                className="userDetails text-center text-md-start "
              >
                <Typography
                  className="userTextColor"
                  sx={{ fontWeight: "600", textTransform: "uppercase" }}
                  variant="h4"
                  component={"li"}
                >
                  {auth.name}
                </Typography>
                <Typography
                  variant="body1"
                  component={"li"}
                  sx={{ fontWeight: "400" }}
                >
                  {auth.email}
                </Typography>
                <Typography
                  variant="body1"
                  component={"li"}
                  sx={{ fontWeight: "400" }}
                >
                  {auth.class_year}
                </Typography>
                <Typography
                  variant="body1"
                  component={"li"}
                  sx={{ fontWeight: "400" }}
                >
                  {auth.city}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* user details send */}
          {/* intelligence cards  */}
          {auth?.mi_intelligence?.length && auth?.mi_intelligence !== 0 ? (
              <Congratulations></Congratulations>
          ) : (
            <Grid
              container
              className="d-flex justify-content-center flex-column align-items-center gap-4 mt-4"
            >
              <Typography
                sx={{ color: "rgba(131, 131, 131, 0.686)" }}
                variant="h5"
              >{`Take our M.I.I.T (Multiple Intelligence, Interest & Talent) Screening now to find what You are Genius At!`}</Typography>
              <Button
                component={Link}
                to={`${MIIT_base_URI}/MITest/pay?email_address=${auth.email}`}
                className="custom-btn"
                variant="contained"
              >
                Take Test
              </Button>
            </Grid>
          )}
          {/* intelligence cards ends */}
          {/* interest cards  */}
          {auth?.mi_interest?.length && auth?.mi_interest?.length !== 0 ? (
           <CongressInterest></CongressInterest>
          ) : auth?.mi_intelligence?.length &&
            auth?.mi_intelligence?.length !== 0 ? (
            <Grid
              container
              className="d-flex justify-content-center flex-column align-items-center gap-4"
            >
              <Typography
                sx={{ color: "rgba(131, 131, 131, 0.686)" }}
                variant="h5"
              >{`Take a detailed second level of Interest Test to narrow down your suggested career path options to final 3, which is your Career Path Trigon.`}</Typography>
              <Button
                component={Link}
                onClick={() =>
                  tracker("Button Click", "MITest Interest Button Click")
                }
                to={`${MIIT_base_URI}/MITest/Interest?email_address=${auth.email}`}
                className="custom-btn"
                variant="contained"
              >
                Take Interest Test
              </Button>
            </Grid>
          ) : (
            ""
          )}
          {/* interest cards ends */}
          {/* CPL cards  */}
          {auth?.mi_interest?.length && auth?.mi_interest?.length !== 0 && clp?.length === 0 ? (
            <CLPOptions />
          ) : clp?.length > 0 ? (
            <Grid container className="userActivityContainer">
              <CLP
                heading={`Hey ${auth.name}, these are your top Customized Learning Plan:`}
                item={clp}
              />
            </Grid>
          ) : (
            ""
          )}
          {/* CPL cards ends */}
        </Grid>
        {/* Main container end */}
      </Box>
    </>
  );
}

function CLP({item,heading}) {
  return (
    <Grid item xs={12}>
    {" "}
    <Grid container sx={{ gap: 4 }}>
      <Grid item xs={12} className="cardHead">
        <Typography variant="h5">{heading}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className="profileCardContainer">
          {/* // iterate */}
          {item.map((row) => (
            <Box className="profileCard" component={Link} to={`/careerMap/${row?.id}`} >
              <Box className="profileCardVector">
                <img src={vector} alt="vector"></img>
              </Box>
              <Box className="profileCardImgCLP">
                <img
                  sx={{ width: "100%" }}
                  src={CLPData[row?.cpl_name]?.img}
                  alt="test"
                />
              </Box>
              <Box className="profileCardName">
                <Typography variant="body1">
                  {row?.cpl_name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  </Grid>
  );
}

function Cards({ item, heading }) {
  return (
    <Grid item xs={12}>
      {" "}
      <Grid container sx={{ gap: 4 }}>
        <Grid item xs={12} className="cardHead">
          <Typography variant="h5">{heading}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box  className="profileCardContainer">
            {/* // iterate */}
            {item.map((row) => (
              <Box className="profileCard" >
                <Box className="profileCardVector">
                  <img src={vector} alt="vector"></img>
                </Box>
                <Box className="profileCardImg">
                  <img
                    sx={{ width: "100%" }}
                    src={cardData?.cards[row]?.img}
                    alt="test"
                  />
                </Box>
                <Box className="profileCardName">
                  <Typography variant="body1">
                    {cardData?.cards[row]?.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CLPOptions() {
  let { email } = useSelector((state) => state.auth);
  let [clp, setCLPLocal] = useState([]);
  let [checkOption, setChecked] = useState([]);
  let dispatch = useDispatch();

  async function handleCLPOptions() {
    try {
      let res = await setCLPOptions(email);
      console.log(res);
      if (res?.status !== 200) {
        return dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong.",
          })
        );
      }
      let { data } = res.data;
      setCLPLocal([...data]);
    } catch (error) {
      return dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong.",
        })
      );
    }
  }

  function handleCheck(e) {
    const { name, checked } = e.target;
    if (checked) setChecked((pre) => [...pre, parseInt(name)]);
    else setChecked((pre) => [...pre.filter((row) => row !== parseInt(name))]);
  }
  async function handleSubmission() {
    try {
      if (checkOption.length < 3) {
        dispatch(
          setAlert({
            open: true,
            variant: "warning",
            message: "Please choose 3 option CPL options.",
          })
        );
      }

      // call the api and save the in user profile
      let res = await saveCLPOptions({
        email,
        cpl: checkOption,
      });

      if (res.status !== 200)
        return dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong.",
          })
        );
        let {data} = res?.data
        dispatch(setCLP([...data]))
    } catch (error) {}
  }
  return (
    <>
      {clp.length === 0 ? (
        <Grid
          container
          className="d-flex justify-content-center flex-column align-items-center gap-4"
        >
          <Typography
            sx={{ color: "rgba(131, 131, 131, 0.686)" }}
            variant="h5"
          >{`Choose your CLP options.`}</Typography>
          <Button
            onClick={handleCLPOptions}
            className="custom-btn"
            variant="contained"
          >
            Show Options
          </Button>
        </Grid>
      ) : (
        <Grid
          container
          className="d-flex justify-content-center flex-column align-items-center gap-4"
        >
          <Typography
            sx={{ color: "rgba(131, 131, 131, 0.686)" }}
            variant="h5"
          >{`Here are your CLP option to choose based on your interest.`}</Typography>
          <FormGroup className={"CLP_option_container"}>
            {/* // iterate */}
            {clp.length !== 0 &&
              clp.map((row) => {
                return (
                  <FormControlLabel
                    key={row.id}
                    control={
                      <Checkbox
                        disabled={
                          !checkOption.includes(row.id) &&
                          checkOption.length === 3
                            ? true
                            : false
                        }
                        name={row.id}
                        onChange={handleCheck}
                      />
                    }
                    label={row.cpl_name}
                  />
                );
              })}
          </FormGroup>
          <Box className="flex m-2">
            <Button
              onClick={() => handleSubmission()}
              className="custom-btn"
              variant="contained"
            >
              Submit CLP
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
}

export default Profile;
