import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { path_track } from "../components/services/services";

const useTrackTime = () => {
  const location = useLocation();
  const { auth } = useSelector((state) => state);

  // Using useRef to store the timestamp of the last route change
  const lastRouteChangeTime = useRef(Date.now());

  useEffect(() => {
    const handleTabClose = async (event) => {
      // Calculate the time span when the route changes
      const currentTime = Date.now();
      const time_span = currentTime - lastRouteChangeTime.current;
      const lastURL = localStorage.getItem("lastUrl");

      localStorage.setItem("lastUrl", location.pathname);

      // Log or store the time span (e.g., send it to your server or analytics)
      await path_track({
        email: auth.isAuth ? auth.email : "Not Logged In",
        user_name: auth.isAuth ? auth.name : "Not Logged In",
        time_span,
        path: lastURL,
      });

      // Update the last route change time
      lastRouteChangeTime.current = currentTime;

      // Do not set event.returnValue to prevent the confirmation dialog
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [location.pathname, auth]);

  useEffect(() => {
    // Calculate the time span when the route changes
    const currentTime = Date.now();
    const time_span = currentTime - lastRouteChangeTime.current;
    const lastURL = localStorage.getItem("lastUrl");
    localStorage.setItem("lastUrl", location.pathname);

    // Log or store the time span (e.g., send it to your server or analytics)
    path_track({
      email: auth.isAuth ? auth.email : "Not Logged In",
      user_name: auth.isAuth ? auth.name : "Not Logged In",
      time_span,
      path: lastURL,
    });

    // Update the last route change time
    lastRouteChangeTime.current = currentTime;
  }, [location.pathname]);
};

export default useTrackTime;
