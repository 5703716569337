import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

// images
import eduLogin from "../../assets/edubuk/eduLogIn.png";
import top from "../../assets/images/topVector.png";
import bottom from "../../assets/images/bottomVector.png";

// css
import "../../assets/css/edubuk.css";
import "../../assets/css/utility.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setAuth } from "../../redux/action/action";
import { verifyEmail } from "../services/services";

// components
import { Helmet } from "react-helmet";

//GA
import { tracker } from "../../App";
import useTrackTime from "../../hook/useTrackTime";
import { grey } from "@mui/material/colors";

export default function Login(props) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const timeSpan = useTrackTime();

  useEffect(() => {
    if (auth.isAuth) props.history("/");
  }, [auth.isAuth]);

  function handleValue(e) {
    console.log(e.target);
    // if (e.target.name === 'mobile') {
    //   if (e.target.value.length < 10 || e.target.value.length > 10) {
    //     setError({ [e.target.name]: true });
    //   } else {
    //     setError({ mobile: false });
    //     setValues((old) => ({ ...old, mobile: e.target.value }));
    //   }
    // } else
    // setValues((old) => ({ ...old, [e.target.name]: e.target.value }));
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      tracker("Sign Up", "Button Click ", "User click on sign up button");

      let Values = {
        email_address: e.target.email_address.value,
        password: e.target.password.value,
        mobile_no: e.target.mobile_no.value,
        username: e.target.username.value,
      };
      let response = await verifyEmail(Values);

      if (response.status === 200) {
        tracker("Sign Up", "Sign Up completed", "User completed");
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message:
              response.message || "Verification mail has been dispatched.",
          })
        );
        window.location.pathname = "/login";
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "May be cred were already in use?",
          })
        );
      }
    } catch (err) {
      tracker("Sign Up", "Sign Up fail");
      console.log("error >>", err);
      dispatch(
        setAlert({
          open: true,
          message: "something went wrong",
          variant: "error",
        })
      );
    }
  }

  return (
    <>
      {/* helmet tag  */}
      <Helmet>
        <title>Edubuk || Login</title>
        <meta
          name="description"
          content="Let Sign Up for great exposure of great courses with Class Bazaar."
        />
      </Helmet>
      {/* helmet tag ends  */}
      <Grid container className="eduContainer">
        {/* // form ======  */}
        <Grid item xs={12} className="eduForm" md={6}>
          <img src={bottom} className="bottomVector" alt="bottom" />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <FormGroup>
              <FormControlLabel
                sx={{ paddingBottom: "20px" }}
                className="eduTextColor formFelids"
                labelPlacement="top"
                control={
                  <TextField
                    sx={{
                      paddingTop: "10px",
                      borderRadius: "20px",
                      "-webkit-text-fill-color": "#868686",
                    }}
                    fullWidth
                    type="text"
                    placeholder={"Name"}
                    variant="outlined"
                    size="medium"
                    required
                    name="Name"
                    className="helperText"
                    helperText={
                      <Typography
                        variant="caption"
                      >
                        Please Enter Your Full Name Below exactly as you want to
                        be printed in the Certificate
                      </Typography>
                    }
                    onChange={handleValue}
                  />
                }
                label={
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 600 }}
                    color="eduTextColor textSecondary"
                  >
                    Name
                  </Typography>
                }
              />
              <FormControlLabel
                className="eduTextColor formFelids"
                sx={{ paddingBottom: "20px" }}
                labelPlacement="top"
                
                control={
                  <TextField
                  className="helperText"

                    fullWidth
                    sx={{
                      paddingTop: "10px",
                      borderRadius: "20px",
                      "-webkit-text-fill-color": "#868686",
                    }}
                    type="email_address"
                    placeholder={"Email"}
                    variant="outlined"
                    size="medium"
                    helperText="Please Enter Your Email ID where you want to your result to be sent"
                    required
                    name="email_address"
                    onChange={handleValue}
                  />
                }
                label={
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 600 }}
                    color="textSecondary"
                  >
                    Email
                  </Typography>
                }
              />
              <Button
                size="medium"
                variant="contained"
                type="submit"
                className="eduButtonColor"
              >
                Start Test
              </Button>
            </FormGroup>
          </form>
        </Grid>
        <Grid item xs={12} md={6} className="imageContainer">
          <img src={top} className="topVector" alt="top" />
          <img src={eduLogin} className="mainImage" alt="eduUp" />
        </Grid>
      </Grid>
      {/* <Footer></Footer> */}
    </>
  );
}
