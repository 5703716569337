import { Box, Typography, Link } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// css
import "../../assets/css/aboutUs.css";

// team images
import Himanshi from "../../assets/team/himanshi.jpeg";
import Raj from "../../assets/team/raj.jpeg";
import Nilesh from "../../assets/team/nilesh.jpeg";
import Yashwant from "../../assets/team/yashwant.png";
import useTrackTime from "../../hook/useTrackTime";

const AboutUs = () => {
  const timeSpan = useTrackTime();

  const teamObj = [
    {
      profile: "https://www.linkedin.com/in/mehtarajb/",
      name: "Raj Metha",
      image: Raj,
      role: "CEO & Founder",
    },
    {
      profile: "https://www.linkedin.com/in/nilesh0710/",
      name: "Nilesh Prajapati",
      image: Nilesh,
      role: "CTO",
    },
    {
      profile: "https://www.linkedin.com/in/hrishtami-rao-917478211/",
      name: "Himanshi Rao",
      image: Himanshi,
      role: "Marketing Head",
    },
    {
      profile: "https://www.linkedin.com/in/yashwant-sahu-4309b8195/",
      name: "Yashnwant Sahu",
      image: Yashwant,
      role: "Full Stack Engineer",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <Box sx={{ padding: "3% 5% 0% 5%" }}>
      {/* helmet tag  */}
      <Helmet>
        <link rel="canonical" href="https://www.classbazaar.com/about" />
        <title>About Us | Class Bazaar: Your Online Learning Partner</title>
        <meta name="keywords" content="Online Learning Partner"/>
        <meta
          name="description"
          content="Learn more about Class Bazaar, your trusted partner in online education. Discover our mission, values, and commitment to providing quality online courses and learning experiences."
        />
      </Helmet>
      {/* helmet tag ends  */}

      {/* first section  */}
      <Box className={"textCyan"} mt={5} mb={5}>
        <Typography variant={"h1"} component={'h1'}>
          “Elevate lives by assisting people find what to learn in the fastest
          and most convenient way.”
        </Typography>
      </Box>
      {/* first section ends */}

      {/* second section  */}
      <Box className="secondTextAbout">
        <Box className={"textOrange"}>
          <Typography component={'h2'} variant="h4">About Us</Typography>
        </Box>
        <Typography p={1} variant="body1">
          Class Bazaar is a search engine and reviews website for massive open
          online courses (MOOCs). Class Bazaar aggregates thousands of courses
          from multiple providers so that you find the best courses on almost
          any topic or subject. Our aim is to help you Stop Searching & Start
          Learning! Our catalog has courses from world’ s leading universities
          and organizations.
        </Typography>
        <Box className={"textCyan"}>
          <Typography variant="h4">1,50,000+</Typography>
        </Box>
        <Box className={"textCyan"}>
          <Typography variant="h6">
            Courses from world’s leading universities and organizations
          </Typography>
        </Box>
      </Box>
      {/* second section ends */}

      {/* third section */}
      <Box className="secondTextAbout" mb={5}>
        <Box className={"textOrange"}>
          <Typography variant="h4">
            We are a Learner Supported Community
          </Typography>
        </Box>
        <Typography p={1} variant="body1">
          Class Bazaar is a learner supported community and we make money
          through affiliate marketing links. Basically, we may earn a commission
          when someone clicks through to a course provider and does a paid
          course or a certificate. For a major portion of our catalog we do NOT
          have any affiliate partnerships and do not stand to earn a commission
          however those courses are still very relevant in their respective
          domains so we show them in search results. We NEVER let the affiliate
          relationship influence the course listing.The most important thing for
          us is that you fast track your search of any MOOCs.
        </Typography>
      </Box>
      {/* third section ends */}

      {/* fourth section  */}
      <Box className={"ourTeam"}>
        <Box sx={{ margin: "auto" }} className={"textOrange"}>
          <Typography variant="h6">OUR TEAM</Typography>
        </Box>
        <Box mt={5}>
          <Carousel
            className="card-section-team "
            dotListClass="custom-dot-list-style"
            keyBoardControl={true}
            autoPlaySpeed={1000}
            ssr={true}
            infinite={true}
            responsive={responsive}
          >
            {teamObj.map((row, key) => (
              <Box className="outer_card">
                <Box
                  component={Link}
                  target="_blank"
                  href={row.profile}
                  className="card_img-team"
                >
                  <img src={row.image} alt={key} />
                </Box>
                <Typography variant="h6">{row.name}</Typography>
                <Typography variant="caption">{row.role}</Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
        <Box>
          <Typography p={1} mt={5} variant={"body1"}>
            We are a small team currently working in roles of the business which
            we have never done before. However, we share a common passion for
            online learning and the impact it can have in elevating lives. If
            you too think you feel similarly and want to help people find the
            next jump in their careers, there may be a role for you on our
            growing team. We would love to hear from you. Drop us an email at
            info@classbazaar.com.
          </Typography>
        </Box>
      </Box>
      {/* fourth section ends */}
    </Box>
  );
};

export default AboutUs;
