import {
  Box,
  Typography,
  Link,
  Grid,
  FormControlLabel,
  TextField,
  FormGroup,
  Button,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
// css
import "../../assets/css/edubuk.css";

// image
import pic1 from "../../assets/edubuk/pic1.gif";
import pic2 from "../../assets/edubuk/pic2.gif";
import pic3 from "../../assets/edubuk/pic3.gif";
import pic4 from "../../assets/edubuk/pic4.gif";
import pic5 from "../../assets/edubuk/pic5.gif";
import pic6 from "../../assets/edubuk/pic6.gif";
import pic7 from "../../assets/edubuk/pic7.gif";
import pic8 from "../../assets/edubuk/pic8.gif";
import pic9 from "../../assets/edubuk/pic9.gif";
import useTrackTime from "../../hook/useTrackTime";

const Edubuk = () => {
  const timeSpan = useTrackTime();

  const content = [
    {
      image: pic1,
      title: "Artificial Intelligence and Machine Learning",
      Class: "left",
      content:
        " and Machine Learning are rapidly growing fields that are being applied to a wide range of industries, from healthcare to finance to transportation.",
    },
    {
      image: pic2,
      title: "Big Data Analytics",
      Class: "right",
      content:
        " involves the use of advanced tools and techniques to analyze large and complex data sets, allowing companies to gain valuable insights and make better decisions.",
    },
    {
      image: pic3,
      title: "Cybersecurity",
      Class: "left",
      content:
        " is a critical concern for organizations of all sizes and types, as cyber threats become more sophisticated and pervasive.",
    },
    {
      image: pic4,
      Class: "right",

      title: "Blockchain, Crypto, NFTs and Web3",
      content:
        " are part of the growing ecosystem of decentralized technologies that are transforming various sectors, including finance, gaming, and social media.",
    },
    {
      image: pic5,
      title: "3D Printing",
      Class: "left",
      content:
        " enables the creation of physical objects from digital models, opening up new possibilities in manufacturing, healthcare, and other industries.",
    },
    {
      image: pic6,
      title: "Cloud Computing",
      Class: "right",
      content:
        " is rapidly becoming the go-to solution for organizations seeking scalable and cost-effective IT infrastructure.",
    },
    {
      image: pic7,
      title: "Metaverse, AR, VR, MR",
      Class: "left",
      content:
        " are immersive technologies that offer new possibilities for entertainment, education, and commerce.",
    },
    {
      image: pic8,
      title: "Robotics and Drones",
      Class: "right",
      content:
        " are being used in a wide range of industries, from logistics to agriculture to healthcare, to automate tasks and improve efficiency.",
    },
    {
      image: pic9,
      title: "Edge Computing and Quantum Computing",
      Class: "left",
      content:
        " are emerging technologies that promise to transform the way we process and analyze data, enabling new applications in areas such as autonomous vehicles, smart cities, and advanced materials.",
    },
  ];
  return (
    <>
      <Box p={3}>
        {/* helmet tag  */}
        <Helmet>
          <title>ClassBazaar || Edubuk</title>
          <meta
            name="description"
            content="Let's contact and tell about your experience with ClassBazaar."
          />
        </Helmet>
        {/* helmet tag ends  */}
      </Box>

      {/* top Section  */}
      <Grid container className="eduBukTop">
        <Grid item xs={12} className="textBlueOrange">
          <Typography variant="h4">
            "Equip yourself with the skills to ride the wave of emerging
            technology."
          </Typography>
        </Grid>
        <Grid item xs={12} md={5.8}>
          <Typography variant="body1">
            Edubuk is offering an emerging technology skilling program for
            students that covers a wide range of in-demand technologies such as
            AI, ML, Blockchain, Cybersecurity, Metaverse, Robotics & Drones, AR,
            VR, IoT, 3D Printing, Edge Computing, Quantum Computing, and Cloud
            Computing. The aim of this program is to prepare students for the
            careers of the future, which will likely be heavily driven by these
            new technologies.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5.8}>
          <Typography variant="body1">
            Edubuk's CETA (Certified Emerging Technologies Professional) Program
            offers three levels of certification (Fundamental, Intermediate, and
            Expert) in each of the nine emerging technologies. The program also
            provides internship and job assistance to learners, in addition to
            verifying and certifying their skills and knowledge through their
            Edubuk profile, which is recorded on the Blockchain.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            The program's claim that more than 1 billion jobs will be affected
            by the use of emerging technologies by the biggest Fortune 500
            companies in the world by 2030 underscores the importance of being
            digitally literate and prepared for the changes that are already
            underway in the job market. This program seems to be a promising way
            for students to gain the skills and knowledge they will need to
            succeed in this changing landscape.
          </Typography>
        </Grid>

        <Grid item xs={12} className="textBlueOrange">
          <Typography variant="h6">
            Edubuk's CETA Program covers a wide range of emerging technologies
            that are becoming increasingly important in today's job market.
          </Typography>
        </Grid>

        {content.map((row, key) => (
          <Grid item xs={12} className={row.Class}>
            <Box className="imageEdu">
              <img src={row.image} alt={key} />
            </Box>
            <Box className="contentEdu">
              <Typography variant="body1">
                <b className="textBlueOrange">{row.title}</b>
                {row.content}
              </Typography>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography pl={5} pr={5} variant="body1">
            <b>
              By providing training and certification in these areas, Edubuk's
              CETA Program is preparing learners for the jobs of the future and
              helping to address the skills gap in emerging technologies.
            </b>
          </Typography>
        </Grid>
      </Grid>
      {/* top Section ends */}
    </>
  );
};

export default Edubuk;
