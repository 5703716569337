import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

// images
import signUp from "../../assets/images/signup.png";
import top from "../../assets/images/topVector.png";
import bottom from "../../assets/images/bottomVector.png";

// css
import "../../assets/css/signup.css";
import "../../assets/css/utility.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setAuth } from "../../redux/action/action";
import { verifyEmail } from "../services/services";

// components
import NavBar from "../utils/Navbar";
import Footer from "../utils/Footer";
import { Helmet } from "react-helmet";

//GA
import { tracker } from "../../App";
import useTrackTime from "../../hook/useTrackTime";

export default function Signup(props) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const timeSpan = useTrackTime();
 const [status,setStatus] = useState('class_1-5')
  useEffect(() => {
    if (auth.isAuth) props.history("/");
  }, [auth.isAuth]);

  function handleValue(e) {
    if(e.target.name === 'class_year')
    setStatus(e.target.value)

  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      tracker("Sign Up", "Button Click ", "User click on sign up button");

      let Values = {
        email_address: e.target.email_address.value,
        password: e.target.password.value,
        mobile_no: e.target.mobile_no.value,
        username: e.target.username.value,
        class_year : e.target.class_year.value,
        org : e.target.org.value
      };

      // console.log(Values)
      // return 1;
      let response = await verifyEmail(Values);

      if (response.status === 200) {
        tracker("Sign Up", "Sign Up completed", "User completed");
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message:
              response.message || "Verification mail has been dispatched.",
          })
        );
        window.location.pathname = "/login";
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "May be cred were already in use?",
          })
        );
      }
    } catch (err) {
      tracker("Sign Up", "Sign Up fail");
      console.log("error >>", err);
      dispatch(
        setAlert({
          open: true,
          message: "something went wrong",
          variant: "error",
        })
      );
    }
  }

  return (
    <>
      {/* helmet tag  */}
      <Helmet>
        <title>SignUp</title>
        <meta
          name="description"
          content="Let Sign Up for great exposure of great courses with Class Bazaar."
        />
      </Helmet>
      {/* helmet tag ends  */}
      <Grid container className="signContainer">
        <Grid item xs={12} md={6} className="imageContainer">
          <img src={top} className="topVector" alt="top" />
          <img src={signUp} className="mainImage" alt="signUp" />
        </Grid>
        {/* // form ======  */}
        <Grid item xs={12} className="signUpForm" md={6}>
          <img src={bottom} className="bottomVector" alt="bottom" />
          <center>
            <Typography variant="h4" className="signTextColor b6">
              Sign Up
            </Typography>
            <Typography
              to="/login"
              component={Link}
              className="signTextColor"
              variant="body"
            >
              Already Have An Account? Login
            </Typography>
          </center>
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <FormGroup className={"formFelids"}>
              <FormControlLabel
                className="signTextColor"
                labelPlacement="top"
                control={
                  <TextField
                    fullWidth
                    type="text"
                    placeholder={"Username"}
                    variant="outlined"
                    size="medium"
                    required
                    name="username"
                    onChange={handleValue}
                  />
                }
                label="Username"
              />
              <FormControlLabel
                className="signTextColor"
                labelPlacement="top"
                control={
                  <TextField
                    fullWidth
                    type="email_address"
                    placeholder={"Email"}
                    variant="outlined"
                    size="medium"
                    required
                    name="email_address"
                    onChange={handleValue}
                  />
                }
                label="Email"
              />
              <FormControlLabel
                className="signTextColor"
                labelPlacement="top"
                control={
                  <TextField
                    fullWidth
                    type="number"
                    placeholder={"Contact Number"}
                    variant="outlined"
                    onChange={handleValue}
                    size="medium"
                    required
                    name="mobile_no"
                  />
                }
                label="Mobile"
              />
              <FormControlLabel
                className="signTextColor"
                labelPlacement="top"
                control={
                  <TextField
                    fullWidth
                    type="password"
                    placeholder={"Password"}
                    required
                    variant="outlined"
                    onChange={handleValue}
                    size="medium"
                    name="password"
                  />
                }
                label="Password"
              />

              <FormControlLabel 
                // className="signTextColor"
                labelPlacement="top"
                control={
                  <>
                    <Select
                      name = "class_year"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      onChange={(e)=>handleValue(e)}
                    >
                      <MenuItem selected value={'class_1-5'}>Class 1-5</MenuItem>
                      <MenuItem value={'class_6-9'}>Class 6-8</MenuItem>
                      <MenuItem value={'class_10-12'}>Class 9-12</MenuItem>
                      <MenuItem value={'working_prof'}>Working Professional</MenuItem>
                    </Select>
                  </>
                }
                label="Status"
              />
              <RadioGroup
                      onChange={(e)=>handleValue(e)}
                name = "org"
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="school"
                style={{margin : '0px 18px',display:'flex',flexDirection:'row'}}
              >
                 <FormControlLabel
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 22,
                      },
                      ".MuiFormControlLabel-label": {
                        
                        fontSize: 18,
                        fontWeight : 'bold'
                      }
                    }}
                  className="signTextColor"
                  value="firm"
                  control={<Radio size="large" />}
                  label="Firm"
                />
                <FormControlLabel
                  value="school"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 22,
                    },
                    ".MuiFormControlLabel-label": {
                      
                      fontSize: 18,
                      fontWeight : 'bold'
                    }
                  }}
                  className="signTextColor"
                  control={<Radio size="large" />}
                  label="School"
                />
               
              </RadioGroup>
              <Button
                size="medium"
                variant="contained"
                type="submit"
                className="signButtonColor"
              >
                SignUp
              </Button>
            </FormGroup>
          </form>
        </Grid>
      </Grid>
      {/* <Footer></Footer> */}
    </>
  );
}
