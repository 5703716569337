import {
  Box,
  Typography,
  Link,
  Grid,
  FormControlLabel,
  TextField,
  FormGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
// css
import "../../assets/css/contact.css";
// service
import { contact } from "../services/services";
// image
import contactPic from "../../assets/contact/contact.gif";

// redux
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/action/action";
import { Send } from "@mui/icons-material";
import { tracker } from "../../App";
import useTrackTime from "../../hook/useTrackTime";

const ContactUs = () => {
  const [data, setData] = useState({ isLoading: false });
  const timeSpan = useTrackTime();

  const dispatch = useDispatch();
  function handleData(e) {
    setData((old) => ({ ...old, [e.target.name]: e.target.value }));
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      const FD = new FormData();

      setData((old) => ({ ...old, isLoading: true }));

      FD.append("name", data.customer_name);
      FD.append("email", data.email);
      FD.append("subject", data.subject);
      FD.append("message", data.message);

      let res = await contact(FD);

      if (res.status === 200) {
        tracker("Form", "Contact Form Submitted", "Contact");
        dispatch(
          setAlert({
            variant: "success",
            message: res.data.message,
            open: true,
          })
        );
        setData({ isLoading: false });

        console.log(data);
      }
    } catch (error) {
      setData((old) => ({ ...old, isLoading: false }));

      dispatch(
        setAlert({
          variant: "error",
          message: "Something Went Wrong !!!",
          open: true,
        })
      );
      console.log(error);
    }
  }

  return (
    <>
      <Box sx={{ padding: "3% 5% 0% 5%" }} mb={5}>
        {/* helmet tag  */}
        <Helmet>
          <title>Contact Us - ClassBazaar</title>
        <link rel="canonical" href="https://www.classbazaar.com/contact" />
          <meta
            name="description"
            content="Get in touch with ClassBazaar for any inquiries or support. We're here to assist you with your online learning needs. Contact us today for prompt assistance."
          />
        </Helmet>
        {/* helmet tag ends  */}
        {/* first section */}

        <Grid container mt={5} className={"contactMainContainer"}>
          {/* images banner */}
          <Grid item xs={12} p={2} md={6} className="contactBanner">
            <img alt={"contact_banner"} src={contactPic} />
          </Grid>
          {/* images banner ends  */}
          {/* form  */}
          <Grid
            item
            xs={12}
            md={6}
            component={"form"}
            encType="application/x-www-form-urlencoded"
            method={"post"}
            className="contactForm"
            onSubmit={handleSubmit}
          >
            {/* heading */}
            <Box p={2} className="topFormSection contactTextColor">
              <Typography component={'h1'} variant="h3">Contact Us</Typography>
              <Typography variant="body2">
                You can leave us a message below and our team will get back to
                you or you can directly email us at{" "}
                <a href="/">info@classbazaar.com</a>
              </Typography>
            </Box>

            {/* fields */}
            <FormGroup className="mainForm">
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    variant={"outlined"}
                    required
                    size="medium"
                    name="customer_name"
                    onChange={handleData}
                    value={data.customer_name || ""}
                    placeholder="Enter your name..."
                  />
                }
                label="Name"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    name="email"
                    onChange={handleData}
                    required
                    value={data.email || ""}
                    size="medium"
                    placeholder="Enter your email..."
                  />
                }
                label="Email"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="subject"
                    required
                    onChange={handleData}
                    value={data.subject || ""}
                    size="medium"
                    placeholder="What's your concern..."
                  />
                }
                label="Subject"
                labelPlacement="top"
              />
              <FormControlLabel
                className="contactTextColor"
                control={
                  <TextField
                    fullWidth
                    name="message"
                    onChange={handleData}
                    value={data.message || ""}
                    size="medium"
                    placeholder="Enter your message..."
                  />
                }
                label="Message"
                labelPlacement="top"
              />
              <Button
                className="contactFromButton"
                disabled={data.isLoading}
                type={"submit"}
                startIcon={
                  data.isLoading === true ? (
                    <CircularProgress size={"20px"} />
                  ) : (
                    <Send />
                  )
                }
                variant="contained"
              >
                Submit
              </Button>
            </FormGroup>
          </Grid>
          {/* form ends */}
        </Grid>
        {/* first section ends */}
      </Box>
      {/* map  */}
      <Box>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.5650968637888!2d72.90247321457856!3d19.082849787083955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7acaffb7aa1%3A0xd525ff85d8d42479!2sZest%20business%20spaces!5e0!3m2!1sen!2sin!4v1678029968973!5m2!1sen!2sin"
          title="my map"
          width="100%"
          height="450"
          style={{ border: "0px" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
      {/* map ends */}
    </>
  );
};

export default ContactUs;
