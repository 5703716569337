import React, { useReducer, useRef } from "react";
import { Helmet } from "react-helmet";

// vector images
import top from "../../assets/images/topVector.png";
import bottom from "../../assets/images/bottomVector.png";

// css
import "../../assets/css/utility.css";
import "../../assets/css/question.css";
// MUI
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const Question = () => {
  const initialState = {
    offset: 0,
    move: 0,
  };

  const [state, setState] = useReducer(reducer, initialState);

  return (
    <>
      {/* helmet tag  */}
      <Helmet>
        <title>Question</title>
        <meta
          name="description"
          content="Let plan your studies with Class Bazaar."
        />
      </Helmet>
      {/* helmet tag ends  */}
      <Grid container className="question-main-container">
        <Grid item xs={12} md={6} className="imageContainer">
          <img src={top} className="topVector" alt="top" />
        </Grid>
        <Grid item xs={12} className="question-outer-wrapper">
          <Series move={state.move} setState={setState} />
        </Grid>
        <Grid item xs={12} md={6} className="imageContainer">
          <img src={bottom} className="bottomVector" alt="bottom" />
        </Grid>
      </Grid>
    </>
  );
};

function Series({ move, setState }) {
  function handle(position) {
    setState({
      type: "Set_Offset",
      payload: {
        move: position,
      },
    });
  }
  return (
    <Box className="question-inner-wrapper" sx={{ right: `${move * 103.5}%` }}>
      {/* question 1 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">What people call you?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="Your Name Here"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(1)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 2 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">What is Your Email?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="Your Email Here"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(2)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 3 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">What is Your Contact no?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="Your Contact no"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(3)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 4 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">Select a Skill to Learn?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="Enter A Skill"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(4)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 5 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">
            How Extensive is your Knowledge on this subject?
          </Typography>
        </Box>
        <Box className="field-wrapper">
          {/* <TextField
            variant="standard"
            fullWidth
            placeholder="Your Name Here"
            size="larger"
          /> */}
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(5)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 6 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">What are you aiming to 
achieve?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="Your Goal"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(6)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 7 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">What is the desired time frame
to accomplish this goal?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="In Weeks"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(7)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      {/* question 8 */}
      <Box className="series-container">
        <Box className="question-text">
          <Typography variant="h2">Is there anything in this 
Subject that catches your 
attention?</Typography>
        </Box>
        <Box className="field-wrapper">
          <TextField
            variant="standard"
            fullWidth
            placeholder="Enter Your Input"
            size="larger"
          />
        </Box>
        <Box className="button-wrapper">
          <Button onClick={() => handle(0)} size="large" variant="contained">
            Next
          </Button>
          <Button size="large" variant="contained">
            Skip
          </Button>
        </Box>
      </Box>
      <Box className="series-container"></Box>
    </Box>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "Set_Offset":
      return (state = { ...state, ...action.payload });
    default:
      return state;
  }
}

export default Question;
