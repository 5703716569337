export const INTELLIGENCES = {
    "Linguistic" : {
        heading : "Linguistic Intelligence(Word Smart)",
        content : "This refers to the ability to use, write and speak words effectively. Individuals with high Linguistic Intelligence have developed auditory skills and often think in words. They like reading, writing stories or poetry etc. This intelligence type is most prevalent in successful writers, speakers, poets, journalists etc."
    },
    "Naturalistic" : {
        heading : "Naturalistic Intelligence(Nature Smart)",
        content : "This refers to the ability to differentiate between living things and other features of nature like different types of rocks, plants, clouds, animals, flowers, environmental patterns etc. This intelligence type is most prevalent in archaeolo-gists, zoologists, botanists, geologists etc"
    },
    "Logical-Mathematical" : {
        heading : "Logical-Mathematical Intelligence or (Number and Reasoning Smart)",
        content : "This refers to the ability to think logically, analyse problems, and perform mathematical calculations. Individuals with high Logical Mathematical Intelligence like to experiment, solve puzzles, write computer software programs etc. This intelligence type is most prevalent in scientists, engineers, , computer experts, accountants, statisticians, analysts etc."
    },
    "Interpersonal" : {
        heading : "Interpersonal Intelligence (People Smart)",
        content : "This refers to the ability to interact and relate with others in an effective way. Individuals with high Interpersonal Intelligence have many friends, can easily connect with strangers, and have empathy for others. This intelligence type is most prevalent in HR professionals, mediators, leaders, counsellors, politicians, sales-people etc."
    },
    "Visual-Spatial" : {
        heading : "Visual-Spatial Intelligence (Picture Smart)",
        content : "This refers to the ability to visualise things in different ways and understand the relationship between images and its meanings clearly. Individuals with high Visual-Spatial Intelligence think in terms of pictures, like to draw paintings, read maps, create graphics, photographs, 3D models etc. This intelligence type is most prevalent in artists, designers, cartoonists, story-boarders, architects, photographers, sculptors etc."
    },
    "Musical" : {
        heading : "Musical Intelligence (Music Smart)",
        content : "This refers to the ability to recognise and reproduce different musical tones, rhythms and sounds. This intelligence type is most prevalent in singers, composers, DJ's, music producers, piano tuners, acoustic engineers etc."
    },
    "Bodily-Kinesthetic" : {
        heading : "Bodily-Kinesthetic Intelligence (Body Smart)",
        content : "This refers to the ability to use the body in a balanced, co-ordinated and structured manner. Individuals with high Bodily-Kinesthetic Intelligence have keen sense of body awareness and communicates well with their body-language and movements like in dancing and sports activities. This intelligence type is most prevalent in dancers, actors, athletes, stage performers, fire-ghters etc."
    },
    "Intrapersonal" : {
        heading : "Intrapersonal Intelligence (Self- Smart)",
        content : "This refers to the ability to be self-aware and understand oneself. Individuals with high Intrapersonal Intelligence understand one’s own interests, goals, shy away from others, possess strong will and coundence. This intelligence type is most prevalent in psychologists, therapists, counsellors, entrepreneurs etc."
    },
    "Existential" : {
        heading : "Existential Intelligence (Life-Smart)",
        content : "This refers to one’s willingness to know about human existence such as meaning of life, why we die, existence of life after birth, existence of God, where Heaven is etc. This intelligence type is most prevalent in yoga gurus, Meta physicists, psychologists, meditation gurus etc."
    },
}

export const Linguistic = {
    heading: "Linguistic Intelligence",
    ul: [
      ["Determination", "Perseverance", "Enthusiasm", "Imagination"],
      ["Commitment", "Passionate", "Self Discipline", "Patience"]
    ],
  };

   
 

    
 