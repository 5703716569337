import React, { useCallback, useRef, useState } from "react";
import "../../assets/css/floatingSearch.css";
import { Box, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { search } from "../services/services";
import { Link, useNavigate } from "react-router-dom";

function FloatingSearchBar() {
  const navigate = useNavigate();
  const input = useRef(null);
  const controllerRef = useRef(null);
  const inputRef = useRef(""); // Use ref to track the search input value
  const [state, setState] = useState({
    open: false,
    search: "", // Keep search state to handle suggestion list updates
    suggestion: [],
  });

  // Create a debounced search function
  const searchOut = useCallback(
    debounce(async (value) => handleChange(value), 800),
    []
  );

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  }

  // Handle search input change and prevent unnecessary API calls
  async function handleChange(value) {
    // If the value is empty, reset the suggestions
    if (!value) {
      setState((old) => ({
        ...old,
        suggestion: [],
      }));
      return;
    }

    // If the new value is the same as the previous, avoid unnecessary API calls
    if (inputRef.current === value) return;

    // Store the current search value in the ref
    inputRef.current = value;

    // Abort any ongoing requests before making a new one
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      // Make the API call to fetch search suggestions
      const result = await search(value, controller.signal);
      setState((old) => ({
        ...old,
        suggestion: result.data.data,
      }));
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching suggestions:", error);
      }
    }
  }

  // Handle change on input field
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Only update the search state and trigger the debounced search if the value has changed
    setState((old) => ({
      ...old,
      search: value, // Update the controlled search state for suggestions
    }));

    searchOut(value); // Trigger the debounced search
  };

  function fireSearch(searchValue) {
    if(searchValue)
    navigate(`/listing/${removeSpecialCharacters(searchValue)}`);
  }
  function reset() {
    setState({
      open: false,
      search: "", // Keep search state to handle suggestion list updates
      suggestion: [],
    });
  }

  return (
    <div className="search-outer" onMouseLeave={reset}>
      <SuggestionBox fireSearch={fireSearch} list={state.suggestion} />
      <Box className="search-container">
        {/* Search bar */}
        <TextField
          inputProps={{
            autoComplete: "off", // Prevents browser suggestions (Google search suggestions)
            spellCheck: "false", // Disable spell checking
            form: {
              autocomplete: "off", // Also make sure the form autocomplete is off
            },
          }}
          sx={{ width: "300px" }}
          id="search-bar"
          className="search-field"
          placeholder="Search"
          size="small"
          ref={input}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              fireSearch(state.search)
            }
          }}
          name="search"
          value={state.search} // The controlled search state
          onChange={handleInputChange} // Handle input change
          variant="outlined"
        />

        {/* Search button */}
        <SearchIcon
          onClick={() => fireSearch(state.search)}
          sx={{ color: "white" }}
          fontSize="large"
        />
      </Box>
    </div>
  );
}

function removeSpecialCharacters(text) {
  text = text.replace(/ /g, "-");
  return text;
}



function SuggestionBox({ list }) {
  return (
    <Box className="suggestion-box">
      {list.length ? (
        list.map((row, i) => (
          <Box
            component={Link}
            to={`/listing/${removeSpecialCharacters(row?.title)}`}
            key={`suggestion-${i}`}
            className="suggestion-item"
          >
            <ImportContactsIcon size="small" color="primary" />
            <Typography variant="body2">
              {row?.title} -{" "}
              <Typography component="span" variant="subtitle2">
                {row?.provider}
              </Typography>
            </Typography>
          </Box>
        ))
      ) : (
        <Box>
          <Typography color="primary" component="b">
            Let&apos;s Search Something New !!!
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FloatingSearchBar;
